.video-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
}
.vc-button {
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:min(30px,1vw);
  width: 60px !important;
  height: 60px !important;
  > *{
    font-size: 28px !important;
  }
 
}
.vc-dropdown-button {
  border-radius: 20px;
  border: 1px solid #fff;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-right:min(30px,1vw);
  > .ant-btn {
    font-size: 30px;
    color: #fff;
    display: flex;
    height: 60px !important;
    align-items: center;
    border-width: 0 !important;
    padding: 0;
    margin-right: 5px;
    &:hover {
      color: #40a9ff;
    }
  }
  > .ant-btn[disabled]{
    background: transparent;
    color: #999;
  }
  > .ant-dropdown-trigger {
    width: 20px !important;
    margin-right: 0;
    margin-left: 3px;
  }
}
.vc-dropdown-menu {
  border: 1px solid #747487;
  background: rgba(0, 0, 0, 0.9) !important;
  .ant-dropdown-menu-item {
    padding: 10px 30px;
    color: #ccc;
    position: relative;
    .anticon-check {
      position: absolute;
      left: 10px;
      top: 15px;
    }
    &:hover {
      color: #40a9ff;
    }
  }
  .ant-dropdown-menu-item-group-title {
    color: #fff;
    font-weight: 700;
  }
}

.camera-feed {
  margin: 16px;
  border-radius: 16px;
  width: 468px;
  height: 300px;
  // display: none;
  opacity: 0;
  height: 1px;
}